export default class User {
  createdAt;
  department;
  email;
  firstName;
  fullNameUppercase;
  function;
  group;
  id;
  isADAccountEnabled;
  isOnCall;
  lastBatchId;
  locationCountry;
  locationOffice;
  novatedProjectCode;
  payglobalUserId;
  payglobalUsername;
  phoneNumber;
  plannedTerminationDate;
  positionTitle;
  reportingManagerPayglobalUsername;
  startDate;
  surname;
  team;
  teamMemberLevel;
  updatedAt;
  userPrincipalName;
  visaCode;
  visaEffectEndDate;
  visaEffectStartDate;

  hasFullAccess() {
    const EMPLOYEE_SERVICE_DEPARTMENTS = ["PEOPLE CONSULTING", "EMPLOYEE LIFECYCLE & AUTOMATIO"];
    const EXCEPTION_LIST_UPN = ["DEAN.NOVELLY@MYOB.COM"];

    /** returns true if
     * user department includes EMPLOYEE_SERVICE_DEPARTMENTS or
     * user UPN includes exception list EXCEPTION_LIST_UPN or
     * user position title is HEAD OF DIGITAL EXPERIENCE
     */
    return (
      EMPLOYEE_SERVICE_DEPARTMENTS.includes(String(this?.department || "").toUpperCase()) ||
      EXCEPTION_LIST_UPN.includes(String(this?.userPrincipalName || "").toUpperCase()) ||
      String(this?.positionTitle || "").toUpperCase() === "HEAD OF DIGITAL EXPERIENCE"
    );
  }
}
